import React from 'react';

const Privacy = () => {
    return (
        <div>
            Developed by
            <a style={{ display: "inline" }} href='https://www.linkedin.com/in/varunguda' target='_blank' rel="noreferrer" className="inferior-btn">Varun Sai Guda</a>
        </div>
    )
}

export default Privacy
