export const USER_ADDRESS_ADD_REQUEST = "USER_ADDRESS_ADD_REQUEST";
export const USER_ADDRESS_ADD_SUCCESS = "USER_ADDRESS_ADD_SUCCESS";
export const USER_ADDRESS_ADD_FAILURE = "USER_ADDRESS_ADD_FAILURE";
export const USER_ADDRESS_ADD_RESET = "USER_ADDRESS_ADD_RESET";


export const USER_ADDRESS_UPDATE_REQUEST = "USER_ADDRESS_UPDATE_REQUEST";
export const USER_ADDRESS_UPDATE_SUCCESS = "USER_ADDRESS_UPDATE_SUCCESS";
export const USER_ADDRESS_UPDATE_FAILURE = "USER_ADDRESS_UPDATE_FAILURE";
export const USER_ADDRESS_UPDATE_RESET = "USER_ADDRESS_UPDATE_RESET";


export const USER_ADDRESS_DELETE_REQUEST = "USER_ADDRESS_DELETE_REQUEST";
export const USER_ADDRESS_DELETE_SUCCESS = "USER_ADDRESS_DELETE_SUCCESS";
export const USER_ADDRESS_DELETE_FAILURE = "USER_ADDRESS_DELETE_FAILURE";
export const USER_ADDRESS_DELETE_RESET = "USER_ADDRESS_DELETE_RESET";


export const GET_USER_ADDRESSES_REQUEST = "GET_USER_ADDRESSES_REQUEST";
export const GET_USER_ADDRESSES_SUCCESS = "GET_USER_ADDRESSES_SUCCESS";
export const GET_USER_ADDRESSES_FAILURE = "GET_USER_ADDRESSES_FAILURE";
export const GET_USER_ADDRESSES_RESET = "GET_USER_ADDRESSES_RESET";


export const USER_ORDERS_REQUEST = "USER_ORDERS_REQUEST";
export const USER_ORDERS_SUCCESS = "USER_ORDERS_SUCCESS";
export const USER_ORDERS_FAILURE = "USER_ORDERS_FAILURE";


export const CANCEL_USER_ORDER_REQUEST = "CANCEL_USER_ORDER_REQUEST";
export const CANCEL_USER_ORDER_SUCCESS = "CANCEL_USER_ORDER_SUCCESS";
export const CANCEL_USER_ORDER_FAILURE = "CANCEL_USER_ORDER_FAILURE";
export const CANCEL_USER_ORDER_RESET = "CANCEL_USER_ORDER_RESET";