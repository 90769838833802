import React from 'react';

import "./Loader2.css";

const Loader2 = () => {
  return (
    <div>
      <span className="loader2"></span>
    </div>
  )
}

export default Loader2
