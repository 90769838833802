
export const USER_CHECK_REQUEST = "USER_CHECK_REQUEST";
export const USER_CHECK_SUCCESS = "USER_CHECK_SUCCESS";
export const USER_CHECK_FAILURE = "USER_CHECK_FAILURE";


export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGIN_USER_RESET = "LOGIN_USER_RESET";


export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE";


export const SIGNUP_USER_REQUEST = "SIGNUP_USER_REQUEST";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";


export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";
export const VERIFY_USER_RESET = "VERIFY_USER_RESET";


export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";


export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";


export const SIGNOUT_USER_REQUEST = "SIGNOUT_USER_REQUEST";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const SIGNOUT_USER_FAILURE = "SIGNOUT_USER_FAILURE";
export const SIGNOUT_USER_RESET = "SIGNOUT_USER_RESET";


export const LIST_ITEMS_REQUEST = "LIST_ITEMS_REQUEST";
export const LIST_ITEMS_SUCCESS = "LIST_ITEMS_SUCCESS";
export const LIST_ITEMS_FAILURE = "LIST_ITEMS_FAILURE";


export const LIST_PRODUCTS_REQUEST = "LIST_PRODUCTS_REQUEST";
export const LIST_PRODUCTS_SUCCESS = "LIST_PRODUCTS_SUCCESS";
export const LIST_PRODUCTS_FAILURE = "LIST_PRODUCTS_FAILURE";



export const EMPTY_LIST_ITEMS_REQUEST = "EMPTY_LIST_ITEMS_REQUEST";
export const EMPTY_LIST_ITEMS_SUCCESS = "EMPTY_LIST_ITEMS_SUCCESS";
export const EMPTY_LIST_ITEMS_FAILURE = "EMPTY_LIST_ITEMS_FAILURE";
export const EMPTY_LIST_ITEMS_RESET = "EMPTY_LIST_ITEMS_RESET";