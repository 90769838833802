
export const ALL_PRODUCT_REQUEST = "ALL_PRODUCT_REQUEST";
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS";
export const ALL_PRODUCT_FAILURE = "ALL_PRODUCT_FAILURE";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAILURE = "PRODUCT_DETAILS_FAILURE";

export const SELLER_PRODUCT_REQUEST = "SELLER_PRODUCT_REQUEST";
export const SELLER_PRODUCT_SUCCESS = "SELLER_PRODUCT_SUCCESS";
export const SELLER_PRODUCT_FAILURE = "SELLER_PRODUCT_FAILURE";

export const BUNDLE_PRODUCTS_REQUEST = "BUNDLE_PRODUCTS_REQUEST";
export const BUNDLE_PRODUCTS_SUCCESS = "BUNDLE_PRODUCTS_SUCCESS";
export const BUNDLE_PRODUCTS_FAILURE = "BUNDLE_PRODUCTS_FAILURE";

export const PRODUCT_REVIEWS_REQUEST = "PRODUCT_REVIEWS_REQUEST";
export const PRODUCT_REVIEWS_SUCCESS = "PRODUCT_REVIEWS_SUCCESS";
export const PRODUCT_REVIEWS_FAILURE = "PRODUCT_REVIEWS_FAILURE";


export const SET_REVIEW_TITLE = "SET_REVIEW_TITLE";
export const SET_REVIEW_COMMENT = "SET_REVIEW_COMMENT";
export const SET_REVIEW_RATING = "SET_REVIEW_RATING";
export const RESET_REVIEW = "RESET_REVIEW";


export const ADD_PRODUCT_REVIEW_REQUEST = "ADD_PRODUCT_REVIEW_REQUEST";
export const ADD_PRODUCT_REVIEW_SUCCESS = "ADD_PRODUCT_REVIEW_SUCCESS";
export const ADD_PRODUCT_REVIEW_FAILURE = "ADD_PRODUCT_REVIEW_FAILURE";


export const DELETE_PRODUCT_REVIEW_REQUEST = "DELETE_PRODUCT_REVIEW_REQUEST";
export const DELETE_PRODUCT_REVIEW_SUCCESS = "DELETE_PRODUCT_REVIEW_SUCCESS";
export const DELETE_PRODUCT_REVIEW_FAILURE = "DELETE_PRODUCT_REVIEW_FAILURE";